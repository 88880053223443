<template>
	<v-card outlined class="border">
		<v-card-title class="teal lighten-5">
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2">mdi-clock-outline</v-icon>
			<div class="my-auto fw-600 text-uppercase fs-18">Sales Overview</div>
			<v-spacer></v-spacer>
			<template v-if="['admin', 'manager', 'master'].includes(checkAdminOrManager())">
				<v-menu left bottom offset-y :max-width="200" :max-height="300" content-class="z-index-11">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							height="35"
							min-height="35"
							color="teal lighten-4"
							class="mr-5"
							depressed
							v-bind="attrs"
							v-on="on"
						>
							<span class="fs-16 text-capitalize text-truncate" style="width: 140px">{{
								dActiveSalesUser.display_name
							}}</span>
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in usersList"
							:key="index"
							@click="filterByUser(item)"
							:class="[
								'py-1',
								{
									'grey lighten-3': JSON.stringify(dActiveSalesUser) == JSON.stringify(item),
								},
							]"
						>
							<v-list-item-avatar size="30" class="my-1 mr-2">
								<v-img
									:src="item.profile_img ? item.profile_img : $assetURL(`media/users/blank.png`)"
								></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title style="min-width: auto">{{ item.display_name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn height="35" min-height="35" color="teal lighten-2" depressed v-bind="attrs" v-on="on">
						<span class="fs-16 text-capitalize px-3">{{ dActiveSalesDuration.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in salesDurationFilterList"
						:key="index"
						@click="filterByDuration(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveSalesDuration.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>
		<div style="height: 50px" class="teal lighten-5"></div>
		<v-layout class="px-4" style="margin-top: -50px">
			<v-flex
				class="border py-2 px-5 mr-5 blue lighten-4 blue--text rounded position-relative overflow-hidden"
			>
				<div class="fw-600" style="font-size: 2.5rem">
					<template v-if="pageLoading">
						<v-progress-circular indeterminate size="24" width="2" color="blue"></v-progress-circular>
					</template>
					<template v-else> {{ getTotal.seo }} </template>
				</div>
				<v-icon class="d-summery-icon" color="blue" size="5rem">mdi-chart-multiple</v-icon>
				<div class="font-size-h6">SEO</div>
			</v-flex>
			<v-flex
				class="border py-2 px-5 green lighten-4 green--text rounded position-relative overflow-hidden"
			>
				<div class="fw-600" style="font-size: 2.5rem">
					<template v-if="pageLoading">
						<v-progress-circular indeterminate size="24" width="2" color="green"></v-progress-circular>
					</template>
					<template v-else> {{ getTotal.software }} </template>
				</div>
				<v-icon class="d-summery-icon" color="green" size="5rem">mdi-chart-multiple</v-icon>
				<div class="font-size-h6">Software</div>
			</v-flex>
			<!-- <v-flex class="border py-5 text-center orange lighten-4 orange--text rounded">
				<div class="fs-24 fw-600">
					1680
				</div>
				<div class="">Postponed Meeting</div>
			</v-flex> -->
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<div>
				<apexchart
					type="line"
					height="450"
					:options="getChartOptions"
					:series="getSeries"
					width="100%"
				></apexchart>
			</div>
		</div>
	</v-card>
</template>
<script>
import { GET_SALES, SET_ACTIVE_FILTER } from "@/core/services/store/dashboard.module.js";
import { mapGetters } from "vuex";
import { filter } from "lodash";
import moment from "moment";
export default {
	name: "SalesOverviewStatus",
	data() {
		return {
			pageLoading: false,
			salesDurationFilterList: [
				{ text: "This Week", value: "this_week" },
				{ text: "This Month", value: "this_month" },
				{ text: "This Quarter", value: "this_quarter" },
				{ text: "This Year", value: "this_year" },
			],
		};
	},
	methods: {
		filterByDuration(item) {
			if (this.dActiveSalesDuration.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_sales_duration",
					value: item,
				});
				this.getSales();
			}
		},
		filterByUser(item) {
			if (this.dActiveSalesUser.id != item.id) {
				this.$store.commit(SET_ACTIVE_FILTER, { key: "active_sales_user", value: item });
				this.getSales();
			}
		},
		getSales() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET_SALES, {
					filter: this.dActiveSalesDuration.value,
					user_filter: this.dActiveSalesUser.id ? this.dActiveSalesUser.id : null,
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["localDB", "dSales", "dActiveSalesDuration", "dActiveSalesUser"]),
		usersList() {
			let _users = this.localDB("allUsers", []);
			_users = filter(_users, (user) => user.users_type == "sales");
			_users.unshift({
				id: 0,
				display_name: "All Users",
				profile_img: this.$assetURL("media/users/blank.png"),
				total_project: 0,
			});
			return _users;
		},
		getTotal() {
			const _sales = this.dSales;
			let _all_seo = 0;
			let _all_software = 0;
			Object.keys(_sales).forEach(function (key) {
				_all_seo = _all_seo + _sales[key].allSeo;
				_all_software = _all_software + _sales[key].allSoftware;
			});
			return {
				seo: _all_seo,
				software: _all_software,
			};
		},
		getSeries() {
			const _sales = this.dSales;
			let _all_seo = [];
			let _all_software = [];
			Object.keys(_sales).forEach(function (key) {
				_all_seo.push(_sales[key].allSeo);
				_all_software.push(_sales[key].allSoftware);
			});
			const _series = [
				{
					name: "Seo",
					type: "column",
					data: _all_seo,
				},
				{
					name: "Software",
					type: "area",
					data: _all_software,
				},
			];
			return _series;
		},
		getChartOptions() {
			const _sales = this.dSales;
			const _duration = this.dActiveSalesDuration.value;
			let _labels = [];
			Object.keys(_sales).forEach(function (key) {
				let _formatted = moment(key, "YYYY-MM-DD").format("DD MMM");
				if (_duration == "this_year") {
					_formatted = moment(key, "YYYY-MM-DD").format("MMM YY");
				}
				_labels.push(_formatted);
			});
			return {
				chart: {
					height: 350,
					type: "line",
					zoom: {
						enabled: false,
					},
				},
				stroke: {
					width: [0, 4],
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [1],
				},
				plotOptions: {
					bar: {
						columnWidth: "20%",
					},
				},
				colors: ["#2196F3", "#4CAF50"],
				fill: {
					opacity: [0.85, 0.25, 1],
					gradient: {
						inverseColors: false,
						shade: "light",
						type: "vertical",
						opacityFrom: 0.85,
						opacityTo: 0.55,
						stops: [0, 100, 100, 100],
					},
				},
				legend: {
					show: true,
					fontSize: "13px",
					fontFamily: "inherit",
					fontWeight: 500,
					offsetY: 20,
					itemMargin: {
						horizontal: 15,
						vertical: 15,
					},
				},
				labels: _labels,
				markers: {
					size: 0,
				},
				/* xaxis: {
					type: "datetime",
					labels: {
						datetimeFormatter: {
							year: "yyyy",
							month: "MMM yyyy",
							day: "dd MMM",
							hour: "HH:mm",
						},
					},
				}, */
				yaxis: {
					stepSize: 1,
					min: 0,
					max: function (val) {
						if (val <= 0) {
							return 5;
						}
						return Math.ceil(val);
					},
					labels: {
						formatter: function (val) {
							return Math.ceil(val);
						},
					},
					title: {
						text: "No. of projects",
						style: {
							fontFamily: "inherit",
							fontSize: 13,
							fontWeight: 500,
						},
					},
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0);
							}
							return y;
						},
					},
				},
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.meeting-stats-count {
	tr:not(.no-border) {
		th,
		td {
			padding: 4px 8px;
			// border:1px solid grey
		}
	}
}
</style>
